<template>
  <div class="content">
    <!-- 弹窗 -->
    <van-popup v-model="show" round position="bottom">
      <!-- 服务网点||种养单位 ||企业类型-->
      <van-cascader v-if="TypeNumber==1||TypeNumber==2||TypeNumber==4" v-model="cascaderValue" title="请选择"
        :options="options" @close="onClose" @finish="onFinish" />
      <!-- 地址 -->
      <van-cascader v-if="TypeNumber==5" :field-names="areaField" v-model="areaCode" title="所在地区" :options="options "
        @change="onChange" @close="onClose" @finish="onFinish">
      </van-cascader>
      <!-- 作物类型 -->
      <van-cascader v-if="TypeNumber==3" :field-names="plantField" v-model="plantValue" title="选择作物类型"
        :options="options" @change="onChange" @close="onClose" @finish="onFinish">
      </van-cascader>

    </van-popup>


    <!-- 服务网点 -->
    <div class="itemInput">
      <van-field label="服务网点" :value="ServerFieldValue" placeholder="请选择" @click="onClick({name:1})" is-link required
        readonly />
    </div>

    <!-- 主体名称 -->
    <div class="itemInput" v-if="identityType!=2">
      <van-field label="名称" v-model="name" placeholder="请输入用户名" readonly />
    </div>
    <!-- 企业类型 -->
    <div class="itemInput" v-if="identityType!=2">
      <van-field label="企业类型" placeholder="请选择" :value="TypeFieldValue" @click="onClick({name:2})" is-link required
        readonly />
    </div>
    <!-- 贷款金额 -->
    <div class="itemInput">
      <van-field type="number" v-model="money" @input="changeMoney" label="申请额度" placeholder="请输入" :readonly="!NoEcho"
        required><text slot="button">元</text></van-field>
    </div>
    <!-- 大写 -->
    <div class="itemInput">
      <van-field v-model="bigNumber" label="大写" placeholder="" readonly />
    </div>
    <!-- 资金用途 -->
    <view class="itemInput">
      <van-field v-model="use" label="资金用途" :placeholder="NoEcho ? '请输入':'' " autosize :readonly="!NoEcho"
        type="textarea" />
    </view>
    <!-- 法定代表人 -->
    <div class="itemInput" v-if="identityType!=2">
      <van-field v-model="legalPersonName" label="法定代表人" required readonly :placeholder=" NoEcho ? '请输入':'' ">
        <!-- 设置代理人 -->
        <div v-if="!isShow" @click="agent" slot="button"
          style="border:solid 1px #ccc;  font-size:20rpx; color:black; border-radius: 25rpx; height:50rpx; display: flex; justify-content: center; align-items: center;">
          <!-- <vant-button type="warning" size="mini">设置代理人</vant-button> -->
          <div style="padding: 2rpx 7rpx;">
            设置代理人
          </div>

        </div>
      </van-field>
    </div>
    <!-- 申请人 -->
    <div class="itemInput" v-if="identityType!=2">
      <van-field v-model="applicant" :placeholder="NoEcho ? '请输入':'' " :readonly="!NoEcho" label="申请人" required>
      </van-field>
    </div>
    <!-- 法定身份证号 -->
    <div class="itemInput">
      <van-field v-model="legalPersonNo" :placeholder="NoEcho ? '请输入':'' " onclick="onClickIcon" label="身份证号" required
        readonly />
    </div>

    <!-- 手机号 -->
    <div class="itemInput" v-if="identityType==2">
      <van-field v-model="phone" label="手机号" :placeholder="NoEcho ? '请输入':'' " bind:click-icon="onClickIcon" required
        readonly />
    </div>

    <div v-if="isShow&&identityType!=2">
      <text class="title">代理人：</text>
      <!-- 姓名 -->
      <div class="itemInput">
        <van-field v-model="agentName" label="姓名">
          <div slot="button"
            style="border:solid 1px #ccc;  font-size:20rpx; color:black; border-radius: 25rpx; height:50rpx; display: flex; justify-content: center; align-items: center;">
            <div style="padding: 2rpx 7rpx;" @click="deleteBtn">
              删除代理人
            </div>
          </div>
        </van-field>
      </div>
      <!-- 身份证号 -->
      <div class="itemInput">
        <van-field v-model="agentNo" label="身份证号" />
      </div>
      <!-- 发送验证码按钮和手机号 -->
      <div class="itemInput">
        <van-field v-model="phone" label="手机号" :readonly="!NoEcho">
          <div slot="button" v-if=NoEcho
            style="border:solid 1px #ccc;  font-size:20rpx; color:black; border-radius: 25rpx; height:50rpx; display: flex; justify-content: center; align-items: center;"
            bind:tap="sendCode">
            <div style="padding: 2rpx 7rpx;">
              {{isCountdown ? '倒计时(' + countdown + ')' : '获取验证码'}}
            </div>

          </div>
        </van-field>
      </div>
      <!-- 验证码 -->
      <div class="itemInput">
        <van-field value="" label="验证码" />
      </div>
    </div>
    <text style="margin-bottom:100rpx;"></text>
    <!-- 地址 -->
    <div class="itemInput">
      <van-field label="地址" :value="areaCodeValue" is-link required readonly @click="onClick({name:5})" />
    </div>
    <div v-for="(item,index) in operatList" :key="index">
      <text class="title">种养</text>
      <!-- 种养品种 -->
      <div class="itemInput" v-if="identityType==2">
        <van-field label="种养品种" :value="item.operatCrop" placeholder="请选择" @click="onClick({name:3,index:index})"
          is-link required readonly />
      </div>
      <!-- 种养数量 -->
      <div class="itemInput" v-if="identityType==2">
        <van-field type="number" :value="item.operatCount" @input="breedNumber" label="种养数量">
          <van-button slot="button" size="mini" @click="onClick({name:4,index:index})"
            style="border-bottom: 1px solid #000 !important;">
            {{item.operatUnit}}
          </van-button>

        </van-field>
      </div>
    </div>

    <!-- 年经营收入和实际经营年限 -->
    <div class="itemInput">
      <van-field type="number" v-model="income" label="年经济收入" required :readonly="!NoEcho">
        <div style="color:#404040" slot="button"> 元</div>
      </van-field>
    </div>
    <div class="itemInput">
      <van-field type="number" v-model="operatYear" label="实际经营年限" :readonly="!NoEcho">
        <div style="color:#404040" slot="button"> 年</div>
      </van-field>
    </div>
    <!-- 经营内容 -->
    <div class="itemInput">
      <van-field v-model="operatIntorducation" label="经营内容" :readonly="!NoEcho" autosize />
    </div>
    <!-- 上传营业执照 -->
    <div class="itemInput" style="display:flex" v-if="identityType!=2">
      <div class="titImg">
        <div>营业执照</div>
      </div>
      <div class="L">
        <van-uploader :after-read="afterRead">
          <div class="upBusinessLicenseImg">
            <img v-if="licenseImgShow" width="160rpx" height="100rpx" lazy-load :src="licenseImgShow" />
            <img v-else style="width:160rpx; height:100rpx;" src="../../assets/applyLoan/营业执照 拷贝.png" mode="" />
          </div>
          <div class="cont" style="font-size: 20rpx;">点击上传营业执照</div>
        </van-uploader>
      </div>
      <div class="R">

      </div>
    </div>
    <!-- 上传身份证照片 -->
    <div class="itemInput" style="display:flex">
      <div class="titImg">
        <div>身份证照片</div>
      </div>
      <div class="L">
        <van-uploader :after-read="afterRead1">
          <div class="upBusinessLicenseImg">
            <img v-if="userCardFrontImgShow" width="100" :src="userCardFrontImgShow" />
            <img v-else style="width:120rpx; height:100rpx;" src="../../assets/applyLoan/身份证正反面_身份证 拷贝.png" mode="" />
          </div>
          <div class="cont" style="font-size: 20rpx;">点击上传人面像</div>
        </van-uploader>
      </div>
      <div class="R">
        <van-uploader :after-read="afterRead2">
          <div class="upBusinessLicenseImg">
            <img v-if="userCardBackImgShow" width="100" height="" :src="userCardBackImgShow" />
            <img v-else style="width:120rpx; height:100rpx;" src="../../assets/applyLoan/身份证正反面_身份证2 拷贝.png" mode="" />
          </div>
          <div class="cont" style="font-size: 20rpx;">点击上传国徽像</div>
        </van-uploader>
      </div>
    </div>
    <!-- 其他材料 -->
    <div class="itemInput" style="display: flex; padding-top:20px;">
      <div class="titImg">
        <div>其他材料</div>
      </div>
      <div class="imgsBox" show-upload="false">
        <div class="imgItem" fit="fill" v-for="(item,index) in filesShow " :key="index">
          <div class="deleteBtn" @click="delBtn(index)">
            X
          </div>
          <div class="image" @click="downLoad(item)">
            <van-image v-slot:error width="100" height="100" :src="item.url" mode="">
              <img width="100" height="100" src="../../assets/默认文件.png" mode="" />
            </van-image>
            <!-- <div class="title" slot="error">{{item.name}}</div> -->

          </div>
          <div class="reportName">
            {{item.name}}
          </div>
        </div>
        <van-uploader accept="image/*,.pdf,.doc/*,.excel/*" :after-read="onClick_sc">
          <van-button v-if="NoEcho" @click="onClick_sc" style="margin-top:5rpx;" icon="plus" type="default" square
            hairline block></van-button>
        </van-uploader>

      </div>


    </div>


    <div class="submitBtn">
      <div class="innerBox">
        <button @click="submit">{{isEdit? '修改':'提交'}}</button>
      </div>

    </div>


  </div>
</template>
<script>

  import Vue from 'vue';
  import wx from 'weixin-js-sdk'
  import { mapGetters, mapMutations } from 'vuex'
  import { Field, Image as VanImage, Popup, Cascader, Uploader, Button } from 'vant';
  Vue.use(Field, VanImage, Popup, Cascader, Uploader, Button);
  // 图片上传
  import { uploadFile } from "@/api/fileApi";
  //用户申请贷款
  import {
    selectCategoryName,
    selectDeptName,
    newSelectExport,
    parentAreaTree,
    farmerAndCompanyApply
  } from "@/api/RuralFinance";


  //转大写
  import { digitUppercase } from "@/util/numConvert.js";
  export default {
    name: '',
    components: {

    },
    mixins: [],
    props: {

    },
    data() {
      return {

        financeProductId: "", //查询当前产品的服务网点的financeProductId
        serverId: '', // 查询服务网点下的financeProductId
        departId: "", //部门id
        title: "",
        region: [], // 地区列表
        isShow: true, //是否显示代理人
        countdown: 10, // 倒计时的秒数，可以根据需要设置
        countdownTimer: null, // 存储倒计时的定时器
        isCountdown: false, // 是否正在进行倒计时
        operatList: [{
          operatCrop: "", //种养品种
          operatCount: "", //输入数量
          operatUnit: "", // 地区的类型
        },],
        activeIndex: 0, //点击的下标
        plantValue: '', // 种养品种回显值
        // operatCount: '', // 种养的数量
        // unitValue: '', //单位回显值
        unitObj: '', //单位回显对象
        serviceItem: '', //服务网点
        name: '', // 主体名称
        userType: '', //主体类型
        money: '', //贷款金额
        use: '', //资金用途
        legalPersonName: '', //法定代表人姓名
        name: '', //申请人
        legalPersonNo: '', //法人身份证号
        phone: "", // 法人手机号
        agentName: '', //代理人姓名
        agentNo: '', // 代理人身份证号
        // 代理人手机号
        // 代理人手机验证码
        income: '', //年经营收入
        operatYear: "", //经营年限
        operatIntorducation: "", //经营内容
        licenseImg: '', //营业执照
        licenseImgShow: '', //展示营业执照
        userCardFrontImg: '', //身份证人面像
        userCardFrontImgShow: [], //展示身份证人面像
        userCardBackImg: '', //身份证国徽像
        userCardBackImgShow: [], //展示身份证国徽像
        files: [], //其他材料
        filesShow: [], //展示其他材料
        types: [{
          text: "集体经济组织",
          value: "3",
        },
        {
          text: "农业企业",
          value: "41",
        },
        {
          text: "家庭农场",
          value: "42",
        },
        {
          text: "合作社",
          value: "43",
        },
        ], //企业类型
        show: false, //展示弹窗
        options: [], //展示下拉的可选项
        serverList: [], //服务网点列表
        plantList: [

        ], //种植类型
        unitList: [{
          text: "亩",
          value: "0",
        },
        {
          text: "尾",
          value: "1",
        },
        {
          text: "羽",
          value: "2",
        },
        {
          text: "头",
          value: "3",
        },
        {
          text: "窝",
          value: "4",
        },
        {
          text: "匹",
          value: "5",
        },
        ], //单位
        options: [], //可选地区
        areaCode: '', // 地址编码
        areaCodeValue: '', //地区回显数据
        bigNumber: '', //转成的大写数字
        TypeNumber: "", //下拉类型
        ServerFieldValue: '', //服务网点回显值
        ServerObj: {}, //服务对象
        TypeFieldValue: '', // 企业种类回显值
        TypeObj: {}, //类型对象
        cascaderValue: '', // 回显所选择的对象
        fileList: [{}],
        identityType: '', //身份类型
        isEdit: false, //是否是修改
        applyId: '', //申请单号(传申请单号是修改不传是新增)
        show_sc: false,
        show_sc1: false,
        NoEcho: true, // 用来表示只用来显示不做编辑使用
        applicant: '',
        NoEcho: true,// 用来表示只用来显示不做编辑使用
        // readonly:'',
        // NoEcho:''
        fieldNames: {
          text: 'text',
          value: 'value',
          children: 'children',
        },//服务网点
        areaField: {
          text: 'areaName', value: 'id', children: 'children'
        },//地址
        plantField: {
          text: 'text', value: 'text', children: 'children'
        },//种养品种
        imgurl: '/api/admin/file/get?ossFilePath=',
        //web-view所需要的数据
        param1: "",
        param2: "",
        obj: {},
        propsList: [],
        identityObj: {},
        identityObjList: [],
        baseUrl: window.globalUrl.HOME_API,
        testList: [],
        //  uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`
      }

    },
    computed: {

    },
    created() {
      this.identityObjList = this.$route.query.queryParams
      //  判断是否登录
      if (!JSON.parse(this.$route.query.queryParams.identityObj).identityTypeCode) {
        this.wx.miniProgram.navigateBack()
      } else {
        this.getStorageShow() //获取本地存储的数据回显
        //隐藏头部和底部
        const header = document.querySelector('.header'); // 修改选择器以匹配你的需要
        // const outerElement = header.parentElement;
        // const outerClassName = outerElement.className;
        const noShow = document.querySelector('.noShow')
        const homeManage = document.querySelector('.home-manage')
        const footer = document.querySelector('.footer');
        header.style = "display:none"
        footer.style = "display:none"
        noShow.style = "display:none"
        homeManage.style = "padding-top:0;"

        // let ShowRouter=false
        // this.SET_SHOW_ROUTER(ShowRouter);//存储去掉头部的判断值

        // console.log(this.ShowRouter,'查看這裏沒有值得原因')
        this.identityTypes() //保存身份类型
        this.selectCategoryName() //获取土地类型和种养品种
        this.areaTree()
        this.selectDeptName() //服务网点
        this.newSelectExport()//个人财报
      }
    },
    computed: mapGetters(['topSelectAreacode']),
    mounted() {
      // this.initWx();
    },
    methods: {
      // ...mapMutations(["SET_SHOW_ROUTER"]),
      // initWx() {
      //   wx.config({
      //     debug: false,
      //     appId: "wx1b4a19d2537a3306",
      //     timestamp: Math.floor(Date.now() / 1000),
      //     nonceStr: Math.random().toString(36).substr(2, 15),
      //     signature: signature, // 确保 signature 正确
      //     jsApiList: ["scanQRCode", "chooseImage", "navigateTo"],
      //   });

      //   wx.ready(() => {
      //     console.log("WeChat SDK is ready.");
      //   });
      // },
      // wxf --- 其他上传
      onClose_sc() {
        this.show_sc = false
      },
      async onClick_sc(file) {
        const param = new FormData();
        param.append('file', file.file); // 注意，这里的 file.file 是实际的文件对象
        let res = await uploadFile(param);

        //  this.testList=res
        let fileList = this.files
        fileList.push({
          url: res.data.url,
          name: res.data.sourceFileName,
        })
        console.log(fileList, '上传完图片查看图片结构')
        let fileList2 = this.filesShow

        // 更新状态中的 files2
        fileList2.push({
          url: '/api/admin/file/get?ossFilePath=' + res.data.url,
          name: res.data.sourceFileName,
        })
        this.files = fileList
        this.filesShow = fileList2
      },


      // 上传图片函数
      async chooseImage() {
        const that = this;
        try {
          const res = await wx.chooseMedia({
            count: 1, // 最多可选择的图片张数
          });
          wx.showLoading({
            title: '上传中...',
          })
          // console.log("res", res);
          const tempFiles = res.tempFiles;
          console.log("tempFiles", tempFiles);
          for (let i = 0; i < tempFiles.length; i++) {
            const filePath = tempFiles[i];
            // 上传文件到服务器
            const uploadRes = await new Promise((resolve, reject) => {
              wx.uploadFile({
                url: `${app.globalData.baseURL}/admin/file/upload`, // 上传接口地址
                filePath: filePath.tempFilePath, // 文件路径
                name: 'file', // 服务器接收的文件字段名，根据实际需要修改
                success(uploadRes) {
                  resolve(uploadRes);
                  wx.hideLoading()

                },
                fail(uploadErr) {
                  reject(uploadErr);
                  wx.hideLoading()
                  // 提示用户打开失败
                  wx.showToast({
                    title: '上传图片失败',
                    icon: 'none'
                  });
                }
              });
            });

            const obj = JSON.parse(uploadRes.data).data;
            let fileList = that.data.files
            let fileList2 = that.data.filesShow
            // console.log('获取到fileList嘛？', fileList)
            // console.log('获取到fileList2嘛？', fileList2)
            fileList.push({
              url: obj.url,
              name: obj.sourceFileName,
            })
            // 更新状态中的 files2
            fileList2.push({
              url: `${app.globalData.imgurl}/admin/file/get?ossFilePath=${obj.url}`,
              name: obj.sourceFileName,
            })

            that.setData({
              files: fileList,
              filesShow: fileList2,
              show_sc1: true,
              show_sc: false
            })

          }

        } catch (err) {
          console.error('发生错误：', err);
          // wx.showToast({
          //   title: '王潇锋',
          //   icon: 'none'
          // });
        }
      },
      // 删除代理人
      deleteBtn() {
        if (this.NoEcho) {
          this.isShow = false
        }
      },
      //回退
      navBack() {
        // console.log("返回按钮");
        wx.navigateBack({
          delta: 1,
        });
      },
      //回主页
      toIndex() {
        wx.switchTab({
          url: "/pages/index/index",
        });
      },
      //保存身份类型
      identityTypes() {
        // console.log('这里执行')
        // let obj = localStorage.getItem('identityObj')
        // let type=JSON.parse(obj).content.identityTypeCode
        // console.log('查看type',type)
        // if (type == 2 || type == 4) {
        // this.setData({
        let type = JSON.parse(this.identityObjList.identityObj).identityTypeCode
        this.identityType = type
        // })
        // }

        // console.log(this.data.identityType, '查看身份类型')
        // console.log('这个没有执行嘛')
      },
      //查看服务网点
      async selectDeptName() {
        //隐藏头部和底部
        // const element = document.querySelector('.header'); // 修改选择器以匹配你的需要
        // console.log(element,'这是获取的element')
        // element.style="display:none"
        //
        // console.log( this.$route.params,'this.$route.params.id')
        console.log(JSON.parse(this.identityObjList.financeProductId).financeProductId, '查看this.identityObjList.financeProductId.financeProductId')
        let res = await selectDeptName({
          // financeProductId: 1000000003,
          financeProductId: JSON.parse(this.identityObjList.financeProductId).financeProductId,
          access_token: this.identityObjList.access_token,
          //  identityId:this.identityObjList.identityId,
          identityTypeCode: this.identityObjList.identityTypeCode
          // identityId:'1699297932295016450'
        })
        let data = []
        for (let i = 0; i < res.data.length; i++) {
          data.push({
            text: res.data[i].departName,
            departId: res.data[i].departId,
            value: String(res.data[i].financeProductId),
          })
        }

        if (this.isEdit) {
          //  console.log( JSON.parse(JSON.stringify(data)),'查看深拷贝的值')
          this.options = JSON.parse(JSON.stringify(data))
          // console.log( this.options,'查看值1')
        } else {
          this.options = JSON.parse(JSON.stringify(data)),
            this.serverList = JSON.parse(JSON.stringify(data)),
            this.cascaderValue = data[0].value,
            this.ServerFieldValue = data[0].text,
            this.serverId = data[0].value,
            this.departId = data[0].departId
          // })

        }


      },
      //查看财报
      async newSelectExport() {
        let identityObj = JSON.parse(this.identityObjList.identityObj)
        let userInfo = JSON.parse(this.identityObjList.userInfo)
        let params = [{
          userId: userInfo.id,
          identityId: identityObj.identityId,
          title: '年报'
        }, {
          access_token: this.identityObjList.access_token,
          identityId: JSON.parse(this.identityObjList.identityObj).identityId
          // identityId:this.identityObjList.identityId
        }
        ]
        let res = await newSelectExport(params)
        console.log(res, '查看获取的年报')
        let filesList = [] //存储所有的财报

        if (res.data.length) {
          filesList.push({
            name: res.data[0].title,
            url: JSON.parse(res.data[0].files)[0].imgUrl,
          })

        }

        console.log(filesList, 'filesList')
        if (res.data.length) {
          let logMen = this.identityObjList.identityObj.bossName //登录人的姓名

          let newfilesList = [] //存储替换完人名的财报
          for (let i = 0; i < filesList.length; i++) {
            newfilesList.push({
              url: `/api/admin/file/get?ossFilePath=${filesList[i].url}`,
              name: filesList[i].name.replace(logMen, ''),
            })
          }
          this.filesShow = newfilesList
          this.files = filesList
          console.log(this.filesShow, this.files)
        }


      },
      //查看土地与种养种类
      async selectCategoryName() {
        // let topAreaCode = wx.getStorageSync('topAreaCode');
        let areaCode = this.areaCode.length > 6 ? this.areaCode.slice(0, 6) : ''
        let params = {
          areaCode: areaCode
        }
        let res = await selectCategoryName(params)
        let newList = JSON.parse(res.data)
        console.log(newList, '查看newList')

        this.plantList = newList

      },
      //点击展示弹窗
      onClick(item) {
        this.activeIndex = item.index
        this.TypeNumber = item.name
        console.log(this.activeIndex, '查看获取到的index')
        switch (item.name) {
          // 服务商
          case 1:
            //如果是值展示详情，不打开弹窗
            if (this.NoEcho) {
              this.selectDeptName()
              // this.setData({
              this.ServerFieldValue = this.ServerFieldValue
              this.cascaderValue = this.serverId
              this.show = true
              // });
            }
            break;
          // 企业类型
          case 2:
            //如果是值展示详情，不打开弹窗
            if (this.NoEcho) {
              // console.log(this.TypeObj,'查看typeobj')
              // if (this.TypeObj) {
              //    this.cascaderValue= this.TypeObj
              // }
              this.options = this.types
              this.show = true
            }
            break;
          // 种养品种
          case 3:
            //如果是值展示详情，不打开弹窗
            if (this.NoEcho) {
              if (this.areaCode > 6) {
                this.options = this.plantList
                this.show = true

              } else {
                setTimeout(() => {
                  wx.showToast({
                    title: '请先选择地区',
                    duration: 5000,
                  });
                }, 0);
              }
            }
            break;
          //单位
          case 4:
            if (this.NoEcho) {
              this.options = this.unitList
              this.show = true
            }
            break;
          // 地区
          case 5:
            if (this.NoEcho) {
              this.areaTree()
              this.show = true
            }
            break;
        }
        // console.log(this.TypeNumber)
        // console.log(typeof this.TypeNumber)

      },
      //种养数量
      breedNumber(e) {
        this.operatList[this.activeIndex].operatCount = e
      },

      //验证码倒计时
      startCountdown() {
        var self = this;
        if (!this.data.isCountdown) {
          this.setData({
            isCountdown: true,
            countdown: this.data.countdown
          });
          // 设置倒计时
          this.data.countdownTimer = setInterval(function () {
            if (self.data.countdown > 0) {
              self.setData({
                countdown: self.data.countdown - 1
              });
            } else {
              clearInterval(self.data.countdownTimer);
              self.setData({
                isCountdown: false,
                countdownTimer: null,
                countdown: 10,
              });
            }
          }, 1000);
        }
      },
      // 设置代理人
      agent() {
        this.isShow = true
      },
      //本地数据申请回显
      getStorageShow() {
        // let obj = localStorage.getItem('identityObj');
        // let obj=identityObj
        // let identityObj=JSON.parse(obj).content
        let identityObj = JSON.parse(this.identityObjList.identityObj)
        this.name = identityObj.identityName;

        // console.log(identityObj,'查看identityObj')
        // console.log(this.identityType,'查看identityType')
        // 身份证号
        const legalPersonNo = identityObj.bossCardId
        //种养地区单位
        this.operatList[this.activeIndex].operatUnit = this.unitList[0].text
        // this.setData({
        //   [`operatList[${this.data.activeIndex}].operatUnit`]: this.data.unitList[0].text,
        // })
        //申请主体名称
        const name = identityObj.identityName;
        // 申请人
        const applicant = identityObj.contactName
        //法定代表人
        const legalPersonName = identityObj.bossName;
        //营业执照
        if (!this.isEdit) {
          const licenseImg = identityObj.businessLicense ? identityObj.businessLicense : ''
          const licenseImgShow = identityObj.businessLicense ?
            "/api/admin/file/get?ossFilePath=" + `${identityObj.businessLicense}` : ''
          // console.log('查看营业执照', data.licenseImg)
          //法人身份证人面像
          const userCardFrontImg = identityObj.userCardFrontImg ? identityObj.userCardFrontImg : ''
          const userCardFrontImgShow = identityObj.userCardFrontImg ?
            "/api/admin/file/get?ossFilePath=" + `${identityObj.userCardFrontImg}` :
            ''
          //法人身份证国徽像
          const userCardBackImg = identityObj.userCardBackImg ? identityObj.userCardBackImg : ''
          const userCardBackImgShow = identityObj.userCardBackImg ?
            "/api/admin/file/get?ossFilePath=" + `${identityObj.userCardBackImg}` :
            ''
          //赋值
          // this.setData({
          this.licenseImg = licenseImg
          this.licenseImgShow = licenseImgShow
          this.userCardFrontImg = userCardFrontImg
          this.userCardFrontImgShow = userCardFrontImgShow,
            this.userCardBackImg = userCardBackImg,
            this.userCardBackImgShow = userCardBackImgShow
          // })
        }
        //代理人
        const agentName = this.identityType != 2 ? identityObj.contactName : '' //姓名
        //身份证号
        // let agentNo = this.data.identityType != 2 ? identityType.contactCode : ''
        let agentNo = identityObj.bossCardId;
        //手机号
        const phone = identityObj.contactPhone;
        this.legalPersonNo = legalPersonNo
        console.log(this.legalPersonNo, 'this.legalPersonNo')
        this.agentName = agentName
        this.agentNo = agentNo
        this.phone = phone
        this.name = name
        this.applicant = applicant
        this.legalPersonName = legalPersonName


        if (this.identityType != 2) {
          this.areaCode = identityObj.areaCode,
            this.areaCodeValue = identityObj.areaFullName
        } else {
          let identityObj = wx.getStorageSync('identityObj');
          this.areaCode = identityObj.areaCode,
            this.areaCodeValue = identityObj.areaFullName
        }
      },
      // 发送验证码
      sendCode() {
        this.startCountdown()
      },

      //地区
      async areaTree() {
        // let topAreaCode = wx.getStorageSync('topAreaCode');

        // console.log(area)
        let res = await parentAreaTree({
          level: '5',
          parentCode: this.topSelectAreacode
        })
        let data = res.data
        let area = this.checkChild(data)
        // console.log('查看此数据是否还有最后一级', area)

        this.options = area

        // console.log('获取到的地区数据', this.data.options)
      },
      //递归去掉数据最后一级children
      checkChild(data) {
        return data.map(item => {
          if (item.hasOwnProperty('children')) {
            // 如果有children节点 往下递归赋值给children
            item.children = this.checkChild(item.children)
            if (item.children.every(item => !item)) {
              // 如果当前children的元素都为 false, 删除当前节点的children
              delete item.children
            }
            return item
          } else {
            // 如果没有children节点  [false, false, ... false]
            return false
          }
        })
      },

      //贷款金额大写
      changeMoney(e) {
        // console.log(e,'查看e')
        let Money = digitUppercase(this.money)

        // this.setData({
        this.bigNumber = Money
        // })
      },
      //上传营业执照图片
      async afterRead() {
        const param = new FormData();
        param.append('file', file.file); // 注意，这里的 file.file 是实际的文件对象
        let res = await uploadFile(param);
        console.log(res, '查看获取的res')

        this.licenseImg = ''
        this.licenseImgShow = ''
        this.licenseImg = [{
          url: res.data.url,
          name: res.data.sourceFileName,
        }]
        this.licenseImgShow = [{
          url: '/api/admin/file/get?ossFilePath=' + res.data.url,
          name: res.data.sourceFileName
        }]
      },
      //上传人面像照片
      async afterRead1(file) {
        // 此时可以自行将文件上传至服务器
        console.log(file);
        const param = new FormData();
        param.append('file', file.file); // 注意，这里的 file.file 是实际的文件对象
        let res = await uploadFile(param);
        console.log(res, '查看获取的res')
        this.userCardFrontImg = ''
        this.userCardFrontImgShow = ''
        this.userCardFrontImg = res.data.url
        this.userCardFrontImgShow = '/api/admin/file/get?ossFilePath=' + res.data.url
        console.log(this.userCardFrontImg, 'userCardFrontImg')
        console.log(this.userCardFrontImgShow, 'userCardFrontImg')
      },
      //上传国徽像照片
      async afterRead2(file) {
        // 此时可以自行将文件上传至服务器
        console.log(file);
        const param = new FormData();
        param.append('file', file.file); // 注意，这里的 file.file 是实际的文件对象
        let res = await uploadFile(param);
        // console.log(res,'查看获取的res')
        // this.userCardFrontImg=''
        // this.userCardFrontImgShow=''
        this.userCardBackImg = [],
          this.userCardBackImgShow = []
        // this.userCardFrontImg=  res.data.url
        // this.userCardFrontImgShow= '/api/admin/file/get?ossFilePath=' + res.data.url
        this.userCardBackImg = res.data.url
        this.userCardBackImgShow = '/api/admin/file/get?ossFilePath=' + res.data.url
      },

      onClose() {
        this.show = false
      },
      //监听地区
      onChange(e) {
        // console.log(e,'查看e是什么')
        const {
          value,
          selectedOptions,
        } = e;
        // console.log('查看监听值', value, selectedOptions)

        switch (this.TypeNumber) {
          case 3:
            if (selectedOptions.length == 2) {
              this.onClose()
            }
            break;
          case 5:
            if (this.topSelectAreacode.length == 4) {
              if (selectedOptions.length == 3) {
                this.onClose()
              }
            } else if (this.topSelectAreacode.length == 6) {
              if (selectedOptions.length == 2) {
                this.onClose()
              }
            }
            break;
        }
      },
      //下拉选项框选中的值
      onFinish(e) {
        const {
          selectedOptions,
          value,
        } = e;

        switch (this.TypeNumber) {
          case 1:
            let ServerFieldValue = selectedOptions
              .map((option) => option.text || option.name)
              .join('/');
            // console.log(value, '查看value')
            // console.log(selectedOptions, '查看selectedOptions')

            this.ServerFieldValue = ServerFieldValue
            this.ServerObj = value
            this.serverId = value
            this.departId = selectedOptions[0].departId
            break;
          case 2:
            let TypeFieldValue = selectedOptions
              .map((option) => option.text || option.name)
              .join('/');
            this.TypeFieldValue = TypeFieldValue
            this.TypeObj = e.value
            break;
          case 3:
            let plantValue = selectedOptions[selectedOptions.length - 1].text
            let activeIndex = this.activeIndex
            this.operatList[activeIndex].operatCrop = plantValue
            break;
          case 4:
            let unitValue = selectedOptions[0].text
            let value = selectedOptions[0].value
            // let activeIndex = this.activeIndex
            this.operatList[this.activeIndex].operatUnit = unitValue
            this.cascaderValue = value
            break;
          case 5:
            let areaCodeValue = selectedOptions.map((option) => option.areaName).join('/');
            let areaCode = selectedOptions[selectedOptions.length - 1].id
            console.log(areaCodeValue, 'areaCodeValue')
            console.log(areaCode, 'areaCode')
            this.areaCodeValue = areaCodeValue
            this.areaCode = areaCode
            break;
        }
      },
      //提交
      async submit() {

        //非农户类型上传
        if (this.identityType != 2) {
          //上传的图片改上传的样式
          let files = this.files.map(file => ({
            FilesName: file.name,
            imgUrl: file.url
          }));
          let params = [{
            financeProductId: this.serverId, //所属部门所用产品id
            departId: this.departId, //代表部门的id
            areaCode: this.areaCode,
            area: this.areaCodeValue,
            userType: this.TypeObj,
            name: this.name,
            phone: this.phone,
            userCardFrontImg: this.userCardFrontImg ? this.userCardFrontImg : '',
            userCardBackImg: this.userCardBackImg ? this.userCardBackImg : '',
            licenseImg: this.licenseImg ? this.licenseImg : '',
            files: JSON.stringify(files),
            income: this.income,
            operatYear: this.operatYear,
            money: this.money,
            use: this.use,
            agentName: this.agentName,
            agentNo: this.agentNo,
            phone: this.phone,
            operatIntorducation: this.operatIntorducation,
            status: 1,
          }, {
            access_token: this.identityObjList.access_token,
            //  identityId:this.identityObjList.identityId,
            identityType: JSON.parse(this.identityObjList.identityObj).identityTypeCode,
            // identityId:JSON.parse(identityObjList.identityObj).identityId,
            identityId: JSON.parse(this.identityObjList.identityObj).identityId,
          }]
          let res = await farmerAndCompanyApply(params)
          if (res.code == 0) {
            //   window.wx.miniProgram.navigateTo({
            //     appId: "wx1b4a19d2537a3306",   // 小程序id
            //       url: 'pages/index/index'  // 要跳转的小程序页面，可以在url后面携带参数
            // });
            // window.wx.miniProgram.navigateTo({action: 'navigate', data: 'index'})
            // this.wx.miniProgram.navigateBack()
            this.wx.miniProgram.navigateTo({ url: '/pagesA/my-sqjl/sqjl' })
          } else {
            // setTimeout(() => {
            //   wx.showToast({
            //     title: res.msg,
            //     duration: 5000,
            //   });
            // }, 0);

          }
        }
        //农户类型上传
        else {
          // console.log('这里是农户类型上传')
          //上传的图片改上传的样式
          console.log('查看this.files', this.files)
          let files = this.files.map(file => ({
            FilesName: file.name,
            imgUrl: file.url
          }));
          console.log('files', files)
          let params = [{
            financeProductId: this.serverId, //所属部门所用产品id
            departId: this.departId, //代表部门的id
            userType: "2",
            idCardNo: this.legalPersonNo,
            areaCode: this.areaCode,
            area: this.areaCodeValue,
            name: this.agentName,
            phone: this.phone,
            userCardFrontImg: this.userCardFrontImg,
            userCardBackImg: this.userCardBackImg,
            files: JSON.stringify(files),
            income: this.income,
            operatYear: this.operatYear,
            money: this.money,
            use: this.use,
            status: 1,
            operatList: this.operatList
          }, {
            access_token: this.identityObjList.access_token,
            //  identityId:this.identityObjList.identityId
            identityId: JSON.parse(this.identityObjList.identityObj).identityId,
            identityType: 2,
          }]

          // console.log('查看params', params)
          // if (this.isEdit) {
          //   params.applyId = this.applyId
          // }
          let res = await farmerAndCompanyApply(params)


          if (res.code == 0) {
            // this.wx.miniProgram.navigateBack()
            this.wx.miniProgram.navigateTo({ url: '/pagesA/my-sqjl/sqjl' })
            // setTimeout(() => {
            //   wx.showToast({
            //     title: res.msg,
            //     duration: 5000,
            //   });
            // }, 0);

          } else {


          }
        }
      },

      //删除附件中图片
      delBtn(index) {
        console.log(index, '查看获取到的下标')
        if (this.NoEcho) {
          this.files.splice(index, 1)
          this.filesShow.splice(index, 1)
          // files: this.data.files,
          // filesShow: this.data.filesShow

        }
      },
      //点击下载图片及其他文件
      downLoad(item) {
        // console.log(item,'查看item')
        // this.testList= item
        //  let dom = document.createElement("a");
        //   dom.style.display = "none";
        //   dom.href = item.url;
        //   this.testList=item.url
        //   // dom.setAttribute("download", item.name);
        //   document.body.appendChild(dom);
        //   dom.click();
        //   // wx.miniProgram.postMessage({ data: '获取成功' })
        //   //  wx.miniProgram.navigateBack({delta: 1})
        //   wx.miniProgram.postMessage({data:{ name: 'dk',url: item.url }})
        // console.log(e, '查看e是')
        // const imgUrl = e.currentTarget.dataset.item.url
        // let index = e.currentTarget.dataset.item.name.lastIndexOf(".")
        // // //获取后缀
        // let type = e.currentTarget.dataset.item.name.substring(index + 1) // wxs不支持substr
        // if (type == 'png' || type == "jpg" || type == "webp") {
        //   wx.previewImage({
        //     current: imgUrl, // 当前显示图片的链接，即点击后显示的图片
        //     urls: [imgUrl] // 需要预览的图片链接列表
        //   });
        // } else {
        //   wx.downloadFile({
        //     url: `${imgUrl}`,
        //     success(res) {
        //       console.log('res', res)
        //       if (res.statusCode == 200) {
        //         // console.log('是否执行1')
        //         const filePath = res.tempFilePath
        //         wx.openDocument({
        //           filePath: res.tempFilePath,
        //           fileType: `${type}`,
        //           showMenu: true,
        //           // showMenu: true, //关键点
        //           success: function (res) {
        //             console.log('successres', res)
        //             // wx.hideLoading()
        //             console.log('打开文档成功')
        //           },
        //           fail() {
        //             console.log('打开失败')
        //           }
        //         })
        //       }
        //       console.log('接口调用成功', res)
        //     }
        //   })
        // }


      },
      //打开文件并预览
      openFile(filePath, fileName) {
        wx.openDocument({
          filePath: filePath,
          showMenu: true,
          success: function (res) {
            console.log('打开文档成功');
          },
          fail: function (error) {
            console.error('打开文档失败', error);
            wx.showToast({
              title: '打开文件失败',
              icon: 'none'
            });
          }
        });
      },
    }
  };
</script>

<style lang='less' scoped>
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100vh;
    height: 100vh;
    font-family: "Source Han Sans CN", sans-serif;
  }

  div {
    box-sizing: border-box;

  }

  .content {

    background: #F5F9F8;

    .title {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 26rpx;
      color: #999999;
      padding-left: 30rpx;
      padding-top: 30rpx;
    }

    .itemInput {
      width: 100%;
      margin-bottom: 20rpx;
      background: #ffffff;
      padding-top: 20rpx;

      .L {
        width: 40%;
        height: 100%;
        margin-top: 10rpx;

        .img {
          width: 100rpx;
          height: 100rpx;
        }
      }

      .R {
        margin-top: 10rpx;
        width: 40%;
        height: 100%;

        .img {
          width: 100rpx;
          height: 100rpx;
        }
      }


      .titImg {
        width: 30%;
        display: flex;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 26rpx;
        color: #999999;
        padding-left: 15px;
        padding-top: 10px;
        color: black;
        font-size: 12px;
        font-weight: 200;
      }

      .imgsBox {
        margin: 5rpx;
        // margin-bottom: 30rpx;
        display: flex;
        flex-wrap: wrap;

        .imgItem {
          width: 140rpx;
          height: 140rpx;
          margin-right: 5rpx;
          margin-bottom: 40rpx;
          // background: #ccc;
          position: relative;
          display: flex;
          flex-direction: column;

          image {
            width: 100%;
            height: 100%;
            // position: absolute;
          }

          .reportName {
            z-index: 999999;
            font-size: 18rpx;

          }


          .deleteBtn {
            position: absolute;
            right: 0rpx;
            z-index: 99;
            width: 40rpx;
            height: 40rpx;
            border-radius: 50rpx;
            border: solid 1px #ccc;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          van-image {
            .title {
              margin-left: 90rpx;
              margin-top: 5rpx;
              width: 140rpx;
              height: 140rpx;
              // display: flex;
              // justify-content: center;
              // align-items: center;
            }
          }

          image {
            width: 140rpx;
            height: 140rpx;
          }
        }

      }

    }

    .submitBtn {
      width: 100%;
      display: flex;
      padding-top: 20px;
      justify-content: center;
      background: #ffffff;

      .innerBox {
        width: 670px;
        height: 79px;
        display: flex;
        justify-content: center;

        button {

          width: 80%;
          height: 45px;
          background: black;
          color: white;
          border: none;
          border-radius: 40px;
          padding: 15px;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }

  }

  .van-button {
    width: auto !important;
    height: 28rpx !important;
    color: rgb(0, 0, 0) !important;
    font-size: 28rpx !important;
    border: 0rpx dashed #ccc !important;

    // overflow: hidden;
  }


  .button-container {

    display: flex;
    justify-content: center;
    /* 让按钮居中对齐 */
    gap: 10px;
    /* 添加按钮间隔 */
    padding: 30rpx;
    margin-top: 12%;
  }

  .upload-button {
    width: calc(50% - 10px);
    /* 让按钮占据一半宽度，并留出间隔 */
    padding: 10px;
    background-color: #29ad95;
    /* 按钮背景色 */
    color: white;
    /* 按钮文字颜色 */
    border: none;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
  }

  .onClick_sc {
    width: 140rpx !important;
    height: 140rpx !important;
    margin-bottom: 10rpx;
  }

  /* 响应式设计 */
  @media (max-width: 760px) {

    .html,
    body {
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      /* 包括padding和border的计算在内 */
      font-size: 0.5rem;
    }

    .content {
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      /* 包括padding和border的计算在内 */
      font-size: 0.5rem;
    }

    /* .titImg {
    width: 30%;
    display: flex;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 26rem;
    color: #999999;
    padding-left: 0.5rem;
    padding-top: 0.5rem;

    text {
      color: black;
    }
  } */

  }
</style>
